import $ from 'jquery';
import bsCustomFileInput from 'bs-custom-file-input';
import autosize from 'autosize';

$(() => {
    bsCustomFileInput.init();

    const formWithValidation = $('.needs-validation');
    const textarea = $('.ccm-page .contact-form textarea');

    autosize(textarea);

    formWithValidation.on('submit', event => {
        const form = event.target;
        if (form.checkValidity() === false) {
            event.stopImmediatePropagation();
            event.preventDefault();
            event.stopPropagation();
        }
        $(form).addClass('was-validated');
        autosize.update(textarea);
    });

    formWithValidation.on('input', event => {
        const form = event.target;
        if (form.checkValidity()) {
            autosize.update(textarea);
        }
    });

    // Form messages
    let message;
    const contactForm = $('.contact-form');
    if (localStorage.getItem('formMessage')) {
        contactForm
            .find('textarea')
            .focus()
            .val(localStorage.getItem('formMessage'));
        localStorage.removeItem('formMessage');
    }
    $('.js-form-message').on('click', e => {
        message = $(e.currentTarget).attr('data-message');
        localStorage.setItem('formMessage', message);
    });
});
