import $ from 'jquery';
import throttle from 'lodash/throttle';

const setContentOffset = () => {
    $('header.is-fixed')
        .next('.fixed-header-fake-height')
        .css({ height: $('header.is-fixed').outerHeight() });
};

const scroll = () => {
    if ($(window).scrollTop() > 1) {
        $('html').addClass('scrolled');
    } else {
        $('html').removeClass('scrolled');
    }
};

$(() => {
    setContentOffset();
    scroll();

    $(window).on('resize', throttle(setContentOffset, 300));
    $(window).on('scroll', scroll);
});
