/* eslint-disable no-lonely-if */
import $ from 'jquery';
import ssm from '../ssm';

if ($('.breadcrumbs-nav').length) {
    const $breadcrumbsContainer = $('.breadcrumbs-container');
    const $breadcrumbs = $breadcrumbsContainer.find('.breadcrumbs-nav');

    $breadcrumbsContainer.prepend(
        '<div class="scroll-trigger left"><i class="fas fa-angle-left"></i></div>'
    );
    $breadcrumbsContainer.append(
        '<div class="scroll-trigger right"><i class="fas fa-angle-right"></i></div>'
    );

    $breadcrumbs.prepend('<div class="overlay-gradient left"></div>');
    $breadcrumbs.append('<div class="overlay-gradient right"></div>');

    const $breadcrumbsScrollable = $breadcrumbs.find('.breadcrumbs');

    const $scrollTriggerLeft = $breadcrumbsContainer.find(
        '.scroll-trigger.left'
    );
    const $scrollTriggerRight = $breadcrumbsContainer.find(
        '.scroll-trigger.right'
    );

    const $scrollOverlayGradientLeft = $breadcrumbs.find(
        '.overlay-gradient.left'
    );
    const $scrollOverlayGradientRight = $breadcrumbs.find(
        '.overlay-gradient.right'
    );

    const overlayGradientWidth = $scrollOverlayGradientLeft.width();

    // Init widths variables
    let breadcrumbsContainerWidth;
    let breadcrumbsWidth;

    // Init scroll position variables
    let breadcrumbsCurrentScrollLeft;
    let breadcrumbsCurrentScrollRight;

    // Init scroll overlay gradient position variables
    let scrollOverlayGradientLeftPosition;
    let scrollOverlayGradientRightPosition;

    // Init
    setTimeout(() => {
        $breadcrumbsScrollable.scrollLeft(
            $breadcrumbsScrollable.get(0).scrollWidth
        );
        $breadcrumbsContainer.addClass('right visible');
        if (
            $breadcrumbsScrollable.get(0).scrollWidth - 1 >
            $breadcrumbsScrollable.width()
        ) {
            $breadcrumbsContainer.addClass('scrollable');
        }
    }, 0);

    $breadcrumbsScrollable.on('scroll', () => {
        // Calculate widths. Here, because viewport width could change.
        breadcrumbsContainerWidth = $breadcrumbsScrollable.width();
        breadcrumbsWidth = $breadcrumbsScrollable.get(0).scrollWidth;

        // Calculate scroll values
        breadcrumbsCurrentScrollLeft = $breadcrumbsScrollable.scrollLeft();
        breadcrumbsCurrentScrollRight = Math.round(
            breadcrumbsWidth -
                breadcrumbsContainerWidth -
                breadcrumbsCurrentScrollLeft
        );

        // Calculate the position of left gradient
        if (breadcrumbsCurrentScrollLeft > 15) {
            scrollOverlayGradientLeftPosition = 0;
        } else {
            if (breadcrumbsCurrentScrollLeft === 0) {
                scrollOverlayGradientLeftPosition = overlayGradientWidth * -1;
            } else {
                scrollOverlayGradientLeftPosition =
                    breadcrumbsCurrentScrollLeft *
                        ((overlayGradientWidth - 15) / 15) -
                    overlayGradientWidth +
                    15;
            }
        }

        $scrollOverlayGradientLeft.css({
            left: scrollOverlayGradientLeftPosition
        });

        // Calculate the position of right gradient
        if (breadcrumbsCurrentScrollRight > 15) {
            scrollOverlayGradientRightPosition = 0;
        } else {
            if (breadcrumbsCurrentScrollRight === 0) {
                scrollOverlayGradientRightPosition = overlayGradientWidth * -1;
            } else {
                scrollOverlayGradientRightPosition =
                    breadcrumbsCurrentScrollRight *
                        ((overlayGradientWidth - 15) / 15) -
                    overlayGradientWidth +
                    15;
            }
        }

        $scrollOverlayGradientRight.css({
            right: scrollOverlayGradientRightPosition
        });
    });

    ssm('xs,sm,md', () => {
        $scrollTriggerLeft.off('click');
        $scrollTriggerRight.off('click');
    });

    ssm('lg,xl', () => {
        $scrollTriggerLeft.on('click', () => {
            $breadcrumbsScrollable
                .stop()
                .animate({ scrollLeft: 0 }, 500, 'easeOutQuart');
            $breadcrumbsScrollable.scrollLeft(0);
            $breadcrumbsContainer.addClass('left');
            $breadcrumbsContainer.removeClass('right');
        });

        $scrollTriggerRight.on('click', () => {
            $breadcrumbsScrollable
                .stop()
                .animate(
                    { scrollLeft: $breadcrumbsScrollable.get(0).scrollWidth },
                    2000,
                    'easeOutQuart'
                );
            $breadcrumbsContainer.addClass('right');
            $breadcrumbsContainer.removeClass('left');
        });
    });
}
