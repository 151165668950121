import $ from 'jquery';
import throttle from 'lodash/throttle';
import scroll from '../hash-scroll';

const backToTopButton = $('#back-to-top');

function moveBackToTopButton(container, CssVarName) {
    const viewportWidth = document.body.clientWidth;
    const containerCoordinates = container.getBoundingClientRect();
    const computedStyle = getComputedStyle(container);
    const shift =
        viewportWidth - containerCoordinates.right + parseInt(computedStyle.paddingRight, 10);
    document.documentElement.style.setProperty(CssVarName, `${shift}px`);
}

const pushAside = () => {
    if ($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
        backToTopButton.addClass('pushed');
    } else {
        backToTopButton.removeClass('pushed');
    }
};

$(() => {
    pushAside();
    moveBackToTopButton(
        document.querySelector('.footer-bottom > .container'),
        '--back-to-top-right'
    );

    backToTopButton.click(() => {
        scroll($('html, body'));
        return false;
    });

    $(window).on('scroll', throttle(pushAside, 300));
    $(window).on(
        'resize',
        throttle(() => {
            moveBackToTopButton(
                document.querySelector('.footer-bottom > .container'),
                '--back-to-top-right'
            );
        }, 300)
    );
});
