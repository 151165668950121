import $ from 'jquery';
import { config, library, dom } from '@fortawesome/fontawesome-svg-core';

import {
    faSearchPlus,
    faChevronRight,
    faChevronLeft,
    faChevronDown,
    faPhoneAlt,
    faMapMarkedAlt
} from '@fortawesome/pro-regular-svg-icons';

import {
    faHome,
    faAngleUp,
    faFilePdf,
    faFileWord,
    faFileImage,
    faFile,
    faLink,
    faSitemap,
    faChevronRight as faChevronRightLight,
    faChevronLeft as faChevronLeftLight,
    faTimesCircle,
    faCheckCircle,
    faDownload
} from '@fortawesome/pro-light-svg-icons';

import {
    // concrete5 icons - don't remove.
    faArrows,
    faShareAlt,
    faAngleRight,
    faArrowRight,
    faArrowLeft,
    faSpinnerThird,
    faTimes,
    faSquareFull
} from '@fortawesome/pro-solid-svg-icons';

// Run gulp "icons" task to generate "public/application/themes/theme/resources/src/js/icons-autogenerated.js"
import { faFacebookF, faTiktok } from '@fortawesome/free-brands-svg-icons';
import * as iconsAutogenerated from './icons-autogenerated';

// Example custom phone icon
// Use it this way: <i class="custom fa-phone"></i>
// import phone from './icons/phone';
// import email from './icons/email';

config.searchPseudoElements = true;

library.add(
    faArrows,
    faShareAlt,
    faAngleRight,
    faArrowRight,
    faArrowLeft,
    faSpinnerThird,
    faTimes,
    faSquareFull,
    faHome,
    faSearchPlus,
    faAngleUp,
    faChevronRight,
    faChevronLeft,
    faChevronDown,
    faFilePdf,
    faFileWord,
    faFileImage,
    faFile,
    faPhoneAlt,
    faMapMarkedAlt,
    faLink,
    faSitemap,
    faChevronLeftLight,
    faChevronRightLight,
    faTimesCircle,
    faCheckCircle,
    faDownload,
    faFacebookF,
    faTiktok
);

library.add(iconsAutogenerated);

dom.i2svg({ node: $('.ccm-page').get(0) });

// dom.watch({
//     autoReplaceSvgRoot: $('.ccm-page').get(0),
//     observeMutationsRoot: $('.ccm-page').get(0)
// });

/* eslint-disable import/prefer-default-export */
export { dom };
