import $ from 'jquery';
import scroll from '../hash-scroll';

// Change hash
$('[data-toggle="pill"]').on('shown.bs.tab', e => {
    window.history.pushState({}, '', `#${e.target.href.split('#pane-')[1]}`);
});

window.addEventListener('hashchange', () => {
    const { hash } = window.location;
    const hashString = hash.split('#').pop();

    if (hash && $(`#pane-${hashString}`).length) {
        // Open tab content (desktop view)
        $(`.nav-link[href="#pane-${hashString}"]`).trigger('click');
    
        // Open accordion content (mobile view)
        if ($(window).width() < 768) {
            $(`.touch-device .accordion-header a[href="#collapse-${hashString}"]`).trigger('click');
        }
    
        // Scroll to content
        setTimeout(() => {
            scroll($(`#pane-${hashString}`).get(0));
        }, 750);
    }
})