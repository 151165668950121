//	Core
import Mmenu from 'mmenu-js/dist/core/oncanvas/mmenu.oncanvas';

//	Core add-ons
import offcanvas from 'mmenu-js/dist/core/offcanvas/mmenu.offcanvas';
import screenReader from 'mmenu-js/dist/core/screenreader/mmenu.screenreader';
import scrollBugFix from 'mmenu-js/dist/core/scrollbugfix/mmenu.scrollbugfix';

//	Add-ons
import autoHeight from 'mmenu-js/dist/addons/autoheight/mmenu.autoheight';
// import backButton from 'mmenu-js/dist/addons/backbutton/mmenu.backbutton';
// import columns from 'mmenu-js/dist/addons/columns/mmenu.columns';
// import counters from 'mmenu-js/dist/addons/counters/mmenu.counters';
// import dividers from 'mmenu-js/dist/addons/dividers/mmenu.dividers';
// import drag from 'mmenu-js/dist/addons/drag/mmenu.drag';
// import dropdown from 'mmenu-js/dist/addons/dropdown/mmenu.dropdown';
// import fixedElements from 'mmenu-js/dist/addons/fixedelements/mmenu.fixedelements';
// import iconbar from 'mmenu-js/dist/addons/iconbar/mmenu.iconbar';
// import iconPanels from 'mmenu-js/dist/addons/iconpanels/mmenu.iconpanels';
// import keyboardNavigation from 'mmenu-js/dist/addons/keyboardnavigation/mmenu.keyboardnavigation';
// import lazySubmenus from 'mmenu-js/dist/addons/lazysubmenus/mmenu.lazysubmenus';
import navbars from 'mmenu-js/dist/addons/navbars/mmenu.navbars';
// import pageScroll from 'mmenu-js/dist/addons/pagescroll/mmenu.pagescroll';
// import searchfield from 'mmenu-js/dist/addons/searchfield/mmenu.searchfield';
// import sectionIndexer from 'mmenu-js/dist/addons/sectionindexer/mmenu.sectionindexer';
// import setSelected from 'mmenu-js/dist/addons/setselected/mmenu.setselected';
// import sidebar from 'mmenu-js/dist/addons/sidebar/mmenu.sidebar';
// import toggles from 'mmenu-js/dist/addons/toggles/mmenu.toggles';

//	Wrappers
// import angular from 'mmenu-js/dist/wrappers/angular/mmenu.angular';
// import bootstrap from 'mmenu-js/dist/wrappers/bootstrap/mmenu.bootstrap';
// import olark from 'mmenu-js/dist/wrappers/olark/mmenu.olark';
// import turbolinks from 'mmenu-js/dist/wrappers/turbolinks/mmenu.turbolinks';
// import wordpress from 'mmenu-js/dist/wrappers/wordpress/mmenu.wordpress';

Mmenu.addons = {
    //	Core add-ons
    offcanvas,
    screenReader,
    scrollBugFix,

    //	Add-ons
    autoHeight,
    // backButton,
    // columns,
    // counters,
    // dividers,
    // drag,
    // dropdown,
    // fixedElements,
    // iconbar,
    // iconPanels,
    // keyboardNavigation,
    // lazySubmenus,
    navbars
    // pageScroll,
    // searchfield,
    // sectionIndexer,
    // setSelected,
    // sidebar,
    // toggles
};

//	Wrappers
Mmenu.wrappers = {
    // angular,
    // bootstrap
    // olark,
    // turbolinks,
    // wordpress
};

//  Export module
export default Mmenu;

//	Global namespace
if (window) {
    window.Mmenu = Mmenu;
}
