export default {
    prefix: 'custom',
    iconName: 'email',
    icon: [
        17.115,
        12.932,
        [],
        null,
        'M0 0v12.932h17.115V0zm2.453 1.773h12.21L8.556 6.721zm-.68 1.73l6.784 5.499 6.785-5.498v7.652H1.773z'
    ]
};
