export default {
    prefix: 'custom',
    iconName: 'fb',
    icon: [
        9.172,
        17.037,
        [],
        null,
        'M6.469 0a3.657 3.657 0 00-2.696 1.244 4.282 4.282 0 00-1.15 3.016V5.9H0v3.25h2.623v7.887h3.272V9.15h3.277V5.9H5.9V4.225a1.142 1.142 0 01.202-.69.574.574 0 01.459-.28h2.607V0H6.561a3.657 3.657 0 00-.092 0z'
    ]
};
