export default {
    prefix: 'custom',
    iconName: 'instagram',
    icon: [
        16.57,
        16.568,
        [],
        null,
        'M8.285 0C6.106 0 5.824.012 4.967.05 1.916.192.19 1.917.05 4.968.01 5.824 0 6.107 0 8.285c0 2.18.012 2.461.05 3.319.141 3.05 1.864 4.776 4.917 4.916.857.039 1.14.048 3.318.048 2.179 0 2.46-.01 3.319-.048 3.048-.14 4.777-1.864 4.916-4.916.039-.857.05-1.14.05-3.319 0-2.179-.011-2.462-.05-3.318C16.38 1.915 14.656.19 11.604.05 10.746.01 10.467 0 8.285 0zm0 1.977c2.14 0 2.389.006 3.229.044 2.034.094 2.942 1.016 3.035 3.036.039.844.045 1.09.045 3.228 0 2.139-.007 2.39-.045 3.229-.094 2.017-1 2.942-3.035 3.035-.845.039-1.089.045-3.229.045s-2.39-.006-3.228-.045c-2.04-.094-2.943-1.021-3.036-3.037-.038-.844-.044-1.09-.044-3.229 0-2.139.007-2.383.044-3.228.093-2.02.996-2.942 3.036-3.034.845-.037 1.09-.044 3.228-.044zm4.356 1.24a.712.712 0 00-.504 1.217.712.712 0 001.217-.504.716.716 0 00-.713-.713zM8.285 3.9c-3.91 0-5.864 4.719-3.1 7.483 2.765 2.764 7.484.81 7.483-3.1A4.383 4.383 0 008.285 3.9zm-.012 1.977a2.408 2.408 0 11.016 4.814c-3.182-.025-3.193-4.774-.016-4.814z'
    ]
};
