import throttle from 'lodash/throttle';

function calculateAvailableSideSpace() {
    const viewportWidth = document.body.clientWidth;
    const container = document.querySelector('.js-container-side-space');
    const computedStyle = getComputedStyle(container);
    const sideSpace = Math.round(
        (viewportWidth -
            container.offsetWidth +
            parseFloat(computedStyle.paddingLeft) +
            parseFloat(computedStyle.paddingRight)) /
            2
    );
    document.documentElement.style.setProperty('--available-side-space', `${sideSpace}px`);
}

calculateAvailableSideSpace();
window.addEventListener('resize', throttle(calculateAvailableSideSpace, 300));

export default calculateAvailableSideSpace;
