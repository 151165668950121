import {
    Swiper,
    Navigation,
    Pagination,
    Lazy,
    Autoplay,
    A11y,
    Controller,
    EffectCube
} from 'swiper/core';

Swiper.use([Navigation, Pagination, Lazy, Autoplay, A11y, Controller, EffectCube]);

const slider1 = '#slider-home-images';
const slider2 = '#slider-home-text';

function initSliders(s1, s2) {
    const sliderHomeImages = new Swiper(s1, {
        speed: 1000,
        effect: 'cube',
        cubeEffect: {
            shadow: false,
            slideShadows: false
        },
        autoplay: {
            delay: 9000
        },
        observer: true,
        observeParents: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        // Lazy load
        preloadImages: false,
        lazy: {
            checkInView: true,
            loadPrevNext: true,
            loadPrevNextAmount: 1
        },
        pagination: {
            el: '#slider-home-images-pagination',
            type: 'bullets',
            clickable: true,
            renderBullet(index, className) {
                return `<span class='${className}'><span class='swiper-pagination-bullet-inner'></span></span>`;
            }
        },
        on: {
            slideChangeTransitionStart() {
                document.querySelector('.slider-home-images-wrapper').classList.add('is-sliding');
            },
            touchStart() {
                document.querySelector('.slider-home-images-wrapper').classList.add('is-sliding');
            },
            slideChangeTransitionEnd() {
                document
                    .querySelector('.slider-home-images-wrapper')
                    .classList.remove('is-sliding');
            }
        }
    });

    const sliderHomeText = new Swiper(s2, {
        speed: 1000,
        autoplay: false,
        observer: true,
        observeParents: true,
        // Lazy load
        preloadImages: false,
        lazy: {
            checkInView: true,
            loadPrevNext: true,
            loadPrevNextAmount: 1
        },
        on: {
            touchStart() {
                document.querySelector('.slider-home-images-wrapper').classList.add('is-sliding');
            }
        }
    });

    sliderHomeText.controller.control = sliderHomeImages;
    sliderHomeImages.controller.control = sliderHomeText;
}

if (slider1 && slider2) {
    initSliders(slider1, slider2);
}
