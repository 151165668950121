export default {
    prefix: 'custom',
    iconName: 'roller',
    icon: [
        38,
        38,
        [],
        null,
        'M0 0v10.857h32.57V6.785h2.715v5.785l-17.642 5.428v6.43h-2.715V38h8.142V24.424h-2.713V20L38 14.568v-10.5h-5.43V0zm2.713 2.713h27.144v5.43H2.713zm14.93 24.43h2.714v8.142h-2.714z'
    ]
};
