import $ from 'jquery';
import Mmenu from '../vendor_modified/mmenu';

$(() => {
    const hamburger = $('#main-nav-toggle');
    const menu = new Mmenu(
        '.js-main-nav',
        {
            iconPanels: false,
            backButton: true,
            extensions: [
                'border-full',
                'position-front',
                'shadow-panels',
                'pagedim-black',
                'multiline'
            ],
            navbars: [
                {
                    content: ['prev', 'breadcrumbs', 'close']
                }
            ]
        },
        {
            offCanvas: {
                clone: true,
                page: {
                    selector: '.ccm-page'
                }
            }
        }
    );

    const api = menu.API;

    hamburger.on('click', () => {
        api.open();
    });

    api.bind('open:start', () => {
        hamburger.addClass('is-active');
    });
    api.bind('close:start', () => {
        hamburger.removeClass('is-active');
    });
});
