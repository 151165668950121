export default {
    prefix: 'custom',
    iconName: 'key',
    icon: [
        34.015,
        44,
        [],
        null,
        'M17.008 0l-1.093 1.094a22.113 22.113 0 01-14.368 5.73H.001v15.461A21.348 21.348 0 008.31 39.34a27.2 27.2 0 008.252 4.523l.445.137.444-.137a27.2 27.2 0 008.252-4.523 21.349 21.349 0 008.31-17.055V6.825H32.47A22.115 22.115 0 0118.1 1.093zm0 4.08a23.512 23.512 0 0013.914 5.6v12.6a17.98 17.98 0 01-7.15 14.64 22.384 22.384 0 01-6.764 3.799 22.39 22.39 0 01-6.765-3.8 17.981 17.981 0 01-7.15-14.64V9.68a23.513 23.513 0 0013.914-5.6zm0 10.475a4.624 4.624 0 00-1.547 8.988v8.02h3.092v-8.02a4.624 4.624 0 00-1.545-8.988zm-.052 3.091a1.523 1.523 0 01.052 0 1.546 1.546 0 11-1.547 1.547 1.523 1.523 0 011.495-1.547z'
    ]
};
