import $ from 'jquery';
import VisSenseFactory from 'vissense';

$(() => {
    if ($('.silnet-logo-container').length) {
        const VisSense = VisSenseFactory(window);

        const silnetLogoVisibility = VisSense($('.silnet-logo-container')[0], {
            fullyvisible: 0.75
        });

        silnetLogoVisibility
            .monitor({
                fullyvisible() {
                    setTimeout(() => {
                        $('.silnet-logo-image').addClass('animate');
                    }, 500);
                }
            })
            .start();
    }
});
