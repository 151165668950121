import { Swiper, Navigation, Pagination, Lazy, Autoplay, A11y, EffectFade } from 'swiper/core';

Swiper.use([Navigation, Pagination, Lazy, Autoplay, A11y, EffectFade]);

function initSlider() {
    const sliderWhyUs = new Swiper('#slider-why-us', {
        speed: 800,
        effect: 'fade',
        spaceBetween: 50,
        autoplay: {
            delay: 4000
        },
        observer: true,
        observeParents: true,
        // Lazy load
        preloadImages: false,
        lazy: {
            checkInView: true,
            loadPrevNext: true,
            loadPrevNextAmount: 1
        },
        pagination: {
            el: '#slider-why-us-pagination',
            type: 'bullets',
            clickable: true,
            renderBullet(index, className) {
                return `<span class='${className}'><span class='swiper-pagination-bullet-inner'></span></span>`;
            }
        }
    });
    sliderWhyUs.update();
}

if (document.getElementById('slider-why-us')) {
    initSlider();
}
