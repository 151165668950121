export default {
    prefix: 'custom',
    iconName: 'dollar',
    icon: [
        38,
        38,
        [],
        null,
        'M19 0a19 19 0 1019 19A19.022 19.022 0 0019 0zm0 2.924A16.077 16.077 0 112.924 19 16.055 16.055 0 0119 2.924zm-1.463 7.306v1.463a4.385 4.385 0 100 8.77h2.924a1.462 1.462 0 110 2.922h-2.924a1.44 1.44 0 01-1.46-1.461h-2.925a4.407 4.407 0 004.385 4.385v1.46h2.924v-1.46a4.385 4.385 0 100-8.77h-2.924a1.462 1.462 0 110-2.924h2.924a1.44 1.44 0 011.463 1.463h2.922a4.407 4.407 0 00-4.385-4.385V10.23z'
    ]
};
