export default {
    prefix: 'custom',
    iconName: 'location',
    icon: [
        33,
        39.801,
        [],
        null,
        'M16.543 0A16.5 16.5 0 000 16.498c0 7.284 5.235 14.766 15.5 22.547l1 .756 1-.756v.004C27.769 31.268 33 23.784 33 16.5A16.5 16.5 0 0016.543 0zm.031 2.887A13.2 13.2 0 0129.7 16.5c0 5.808-4.337 12.222-13.193 19.152C7.652 28.726 3.312 22.312 3.312 16.5A13.2 13.2 0 0116.575 2.887zm-.062 6.804a6.6 6.6 0 10-.006 13.202l.002-.004a6.6 6.6 0 006.6-6.598 6.6 6.6 0 00-6.596-6.6zm-.026 3.301a3.3 3.3 0 11.02 6.6l.002-.002a3.3 3.3 0 01-3.3-3.299 3.3 3.3 0 013.278-3.299z'
    ]
};
