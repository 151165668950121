export default {
    prefix: 'custom',
    iconName: 'magnifier',
    icon: [
        37.079,
        37.059,
        [],
        null,
        'M14.249 0a14.231 14.231 0 109 25.24l2.51 2.512-2.135 2.135 5.824 5.822a4.442 4.442 0 106.281-6.281l-5.822-5.824-2.135 2.134-2.512-2.51A14.216 14.216 0 0014.25.003zm-.036 2.846a11.363 11.363 0 01.036 0A11.385 11.385 0 112.864 14.23a11.363 11.363 0 0111.35-11.384zm15.69 24.783l3.81 3.81a1.6 1.6 0 01-2.255 2.256l-3.81-3.81z'
    ]
};
