import $ from 'jquery';
import isTouchDevice from '../is-touch-device';

$(() => {
    if (!isTouchDevice()) {
        if (!$('.toolbar-visible').length) {
            $('[data-toggle="tooltip"]').tooltip({
                trigger: 'hover'
            });
        }
    }
});
