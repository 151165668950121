import $ from 'jquery';

$(() => {
    $('.js-toggle-contact-info-top').on('click', function toggleContactInfo() {
        const parent = $(this).parent();
        const container = parent.parent();
        const text = $(this).next();
        const link = text.find('a');

        if ($(window).width() > 1199) {
            link.trigger('click');
            text.animate({ width: 'toggle' });

            const isOpened = parseInt(parent.attr('data-open'), 10);

            if (isOpened === 0) {
                parent.attr('data-open', 1);
            } else {
                parent.attr('data-open', 0);
            }

            const isAtLeastOneOpened = container.find('[data-open="1"]').length;

            if (isAtLeastOneOpened) {
                container.addClass('is-open');
            } else {
                container.removeClass('is-open');
            }
        } else {
            link[0].click();
        }
    });
});
