export default {
    prefix: 'custom',
    iconName: 'reload',
    icon: [
        35.981,
        48,
        [],
        null,
        'M16.831 0L9.15 7.682l7.681 7.68 2.309-2.307-3.54-3.537a14.68 14.68 0 0112.77 24.867l2.307 2.31a17.92 17.92 0 00-15.514-30.41l3.975-3.976zM5.294 11.305a17.927 17.927 0 0015.512 30.414l-3.975 3.972L19.14 48l7.68-7.682-7.68-7.68-2.309 2.307 3.541 3.541A14.682 14.682 0 017.601 13.617z'
    ]
};
