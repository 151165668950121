export default {
    prefix: 'custom',
    iconName: 'phone',
    icon: [
        13.574,
        19.043,
        [],
        null,
        'M4.591 0l-.263.123-2.182 1.014h-.002l-.004.002h-.002l-.01.006-.02.017-.001.002C1.064 1.722.405 2.674.134 4c-.24 1.172-.156 2.628.23 4.207 1.249 5.095 5.426 10.836 9.223 10.836.53 0 1.041-.11 1.524-.33.094-.044 2.102-1.027 2.19-1.07l.273-.137-.14-.274-3.142-6.062-.136-.26-.266.13c-.404.198-1.568.77-1.924.952-.174-.056-.586-.43-1.119-1.305-.77-1.263-1.21-2.58-1.103-3.039l1.935-.95.274-.137-.141-.27L4.722.258zm-.968 2.781L5.07 5.61l-.465.229h-.002l-.041.02c-.914.49-1.149 1.657-.758 3.214.286 1.138.906 2.4 1.655 3.37.833 1.079 1.738 1.705 2.562 1.704.239 0 .468-.05.682-.154l.52-.254 1.456 2.815-.455.224c-.218.1-.454.15-.701.15-1.152 0-2.661-1.114-4.13-3.126C4.01 11.909 2.88 9.54 2.37 7.459c-.335-1.366-.652-3.7.734-4.424z'
    ]
};
