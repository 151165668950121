import $ from 'jquery';
import ssm from 'simplestatemanager';

ssm.addStates([
    {
        id: 'xs',
        query: '(max-width: 575px)'
    },
    {
        id: 'sm',
        query: '(min-width: 576px) and (max-width: 767px)'
    },
    {
        id: 'md',
        query: '(min-width: 768px) and (max-width: 991px)'
    },
    {
        id: 'lg',
        query: '(min-width: 992px) and (max-width: 1199px)'
    },
    {
        id: 'xl',
        query: '(min-width: 1200px) and (max-width: 1439px)'
    },
    {
        id: 'xxl',
        query: '(min-width: 1440px) and (max-width: 1859px)'
    },
    {
        id: 'xxxl',
        query: '(min-width: 1860px)'
    }
]);

const ssmHelper = (id, callback, event = 'enter') => {
    const idArray = id.split(/,\s?/);
    $.each(idArray, (i, ssmID) => {
        ssm.getState(ssmID).attachCallback(event, callback, true);
    });
};

export default ssmHelper;
