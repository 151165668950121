export default {
    prefix: 'custom',
    iconName: 'plus',
    icon: [
        116.953,
        124.213,
        [],
        null,
        'M47.578 0v49.799H0v20.508h47.574v53.906h21.801V70.307h47.578V49.799H69.375L69.379 0z'
    ]
};
