export default {
    prefix: 'custom',
    iconName: 'tool',
    icon: [
        1200,
        1200,
        [],
        null,
        'M926.5 126.5c10.75-10.699 13.949-26.852 8.148-40.852-5.8-14-19.5-23.148-34.648-23.148H670.7c-23.199 0-45.398 9.2-61.852 25.648l-120.7 120.7C471.7 225.297 462.5 247.496 462.5 270.7v258.6c0 3.3-1.3 6.5-3.648 8.852l-370.7 370.7C71.703 925.3 62.504 947.5 62.504 970.704v79.3c0 48.399 39.199 87.5 87.5 87.5h79.301c23.199 0 45.398-9.199 61.852-25.647l370.7-370.7a12.524 12.524 0 018.852-3.649h258.6c23.199 0 45.398-9.199 61.852-25.648l120.7-120.7c16.449-16.449 25.648-38.648 25.648-61.852v-229.3c0-15.148-9.149-28.852-23.148-34.648-14-5.8-30.148-2.601-40.852 8.149l-185.35 185.35a12.524 12.524 0 01-8.852 3.648h-129.3c-6.898 0-12.5-5.602-12.5-12.5v-129.3c0-3.3 1.3-6.5 3.648-8.852l185.35-185.35zm-117.05 11l-121.3 121.35c-16.449 16.449-25.648 38.648-25.648 61.852v129.3c0 48.398 39.199 87.5 87.5 87.5h129.3c23.199 0 45.398-9.2 61.852-25.648l121.35-121.3v138.75c0 3.3-1.3 6.5-3.648 8.852l-120.7 120.7a12.524 12.524 0 01-8.852 3.648h-258.6c-23.199 0-45.398 9.2-61.852 25.648l-370.7 370.7a12.524 12.524 0 01-8.852 3.648H150c-6.899 0-12.5-5.601-12.5-12.5v-79.3c0-3.301 1.3-6.5 3.648-8.852l370.7-370.7c16.449-16.45 25.648-38.648 25.648-61.852v-258.6c0-3.301 1.3-6.5 3.648-8.852l120.7-120.7a12.524 12.524 0 018.852-3.648h138.75z'
    ]
};
