export default {
    prefix: 'custom',
    iconName: 'home',
    icon: [
        37.105,
        39.123,
        [],
        null,
        'M18.559 0l-1.014 1.01L0 18.553v20.57h14.271V24.852h8.563v14.271h14.271v-20.57zm0 4.035l15.7 15.701V36.27h-8.564V21.996H11.424v14.272H2.859V19.734z'
    ]
};
