import $ from 'jquery';
import 'lightgallery.js';
import 'lg-thumbnail.js';
// import 'lg-hash';
import { dom as fontAwesomeDom } from './icons';

const bindLightbox = parent => {
    const lightboxElements = {};

    const selectorsToMatch = [
        'a[rel^=lightbox]',
        '.gallery a',
        'a.lightbox',
        'a[href$=jpg]',
        'a[href$=JPG]',
        'a[href$=jpeg]',
        'a[href$=JPEG]',
        'a[href$=png]',
        'a[href$=PNG]'
    ];

    $.each(selectorsToMatch, (selectorIndex, selector) => {
        parent.find(selector).each((i, e) => {
            const element = $(e);

            // Skip on `.no-lightbox` elements
            if (!element.hasClass('no-lightbox')) {
                const image = element.find('img');

                let caption = null;
                let thumbnailUrl = null;
                let galleryGroup = null;

                if (element.attr('data-caption')) {
                    caption = element.attr('data-caption');
                } else if (element.attr('title')) {
                    caption = element.attr('title');
                } else if (image.length && image.attr('alt')) {
                    caption = image.attr('alt');
                }

                if (image.attr('data-src')) {
                    thumbnailUrl = image.attr('data-src');
                } else {
                    thumbnailUrl = image.attr('src');
                }

                if (element.attr('data-gallery-group')) {
                    galleryGroup = element.attr('data-gallery-group');
                } else if (element.attr('rel')) {
                    galleryGroup = element.attr('rel');
                } else {
                    galleryGroup = 'default';
                }

                const tmpObject = {
                    originalElement: element.get(0),
                    src: element.attr('href'),
                    href: element.attr('href'),
                    thumb: thumbnailUrl,
                    subHtml: caption
                };

                if (typeof lightboxElements[galleryGroup] === 'undefined') {
                    lightboxElements[galleryGroup] = [];
                }

                lightboxElements[galleryGroup].push(tmpObject);
            }
        });
    });

    $.each(lightboxElements, (groupIndex, group) => {
        $.each(group, (i, e) => {
            $(e.originalElement).on('click', () => {
                const gallery = $(e.originalElement);

                gallery.one('onAferAppendSlide.lg', () => {
                    if (!$('.lg-item .spinner').length) {
                        $('.lg-item').append(
                            '<div class="spinner"><i class="fas fa-spinner-third fa-spin"></i></div>'
                        );
                    }

                    if (!$('.lg-toolbar .lg-close .fa-times').length) {
                        $('.lg-toolbar .lg-close').append(
                            '<i class="fas fa-times"></i>'
                        );
                    }

                    if (!$('.lg-actions .lg-prev .fa-arrow-left').length) {
                        $('.lg-actions .lg-prev').append(
                            '<i class="fas fa-arrow-left"></i>'
                        );
                    }

                    if (!$('.lg-actions .lg-next .fa-arrow-right').length) {
                        $('.lg-actions .lg-next').append(
                            '<i class="fas fa-arrow-right"></i>'
                        );
                    }

                    fontAwesomeDom.i2svg({ node: $('.lg-outer').get(0) });
                });

                // eslint-disable-next-line no-undef
                lightGallery(gallery.get(0), {
                    index: i,
                    cssEasing: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
                    controls: true,
                    download: false,
                    thumbnail: true,
                    thumbWidth: 75,
                    thumbContHeight: 75,
                    toggleThumb: false,
                    // hash: true,
                    dynamic: true,
                    dynamicEl: lightboxElements[groupIndex]
                });

                return false;
            });
        });
    });
};

bindLightbox($('body'));

// eslint-disable-next-line import/prefer-default-export
export { bindLightbox };
