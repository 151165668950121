export default {
    prefix: 'custom',
    iconName: 'comments',
    icon: [
        56.139,
        44.145,
        [],
        null,
        'M22.055 0v10.023H0V36.09h6.063l-.094 8.055 10.74-8.055h17.375V26.064h5.346l10.74 8.055-.094-8.055h6.063V0zm4.01 4.01h26.064v18.045h-6.11l.047 3.972-5.3-3.972h-6.684v-12.03h-8.02zM4.01 14.035h26.064v18.043H15.373l-5.299 3.975.047-3.975H4.01z'
    ]
};
